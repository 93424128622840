









































































import { Component, Vue } from 'vue-property-decorator'
import { BButton, BSpinner } from 'bootstrap-vue'
import { cart } from '@/store/cart/cartModule'
import { customer } from '@/store/customer'

@Component({
  components: { BButton, BSpinner }
})
export default class LoyaltyProgram extends Vue {
  private showScanMessage = false

  get isAuthenticated(): boolean {
    return !!customer.customerId
  }

  get isLoading(): boolean {
    return this.scoresCount === null
  }

  get scoresCount(): number | null {
    return customer.scores
  }

  get spend() {
    return customer.spendScores
  }

  get error() {
    return customer.error
  }

  get totalPrice(): number {
    return cart.totalPrice
  }

  get commonPrice(): number {
    return cart.commonPrice
  }

  get notEnoughScores() {
    return this.scoresCount !== null && this.totalPrice &&
      this.scoresCount < this.totalPrice
  }

  get spendBtnDisabled() {
    return !this.totalPrice || !this.commonPrice || this.notEnoughScores
  }

  spendScores(): void {
    cart.payWithScores()
    this.$emit('spend')
  }

  showMessage(): void {
    this.showScanMessage = true
  }
}
