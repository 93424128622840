




































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { BButton, BSpinner } from 'bootstrap-vue'
import PurchaseCancelAlert from '@/components/PurchaseCancelAlert.vue'
import LoyaltyProgram from '@/components/LoyaltyProgram.vue'
import { RouteNames } from '@/router'
import { terminalService } from '@/store/terminalService'
import terminalModule from '@/store/terminal'
import { Provider, SystemState } from '@/graphql/terminal/graphql.schema'
import { Scalars } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'
import { customer } from '@/store/customer'
import { round } from 'lodash-es'

const BasketProduct = () => import('@/components/BasketProduct.vue')

@Component({
  components: { BasketProduct, BButton, BSpinner, PurchaseCancelAlert, LoyaltyProgram }
})
export default class Basket extends Vue {
  readonly RouteNames = RouteNames
  readonly Provider = Provider
  statusFoodCard: SystemState | null = null
  systemState: SystemState[] = []
  lastPayProvider: Provider | null = null

  /**
   * Цена без скидки
   */
  get commonPrice(): number {
    return cart.commonPrice
  }

  /**
   * Расчётная стоимость корзины
   */
  get totalPrice(): number {
    return cart.totalPrice
  }

  /**
   * Определяет будет ли клиент тратить бонусные баллы
   */
  get spendScores(): boolean {
    return customer.spendScores
  }

  get thereIsSale() {
    return this.commonPrice !== this.totalPrice || this.spendScores
  }

  get products() {
    return cart.products
  }

  get totalKcal() {
    return round(this.products
      .reduce((acc, { calories }) => (acc + (calories ?? 0)), 0), 2)
  }

  get removedFromCart() {
    return cart.productsHistory
  }

  get deletedItems() {
    return cart.deletedItems
  }

  async changeQuality(quality:number, productId:Scalars['ID']):Promise<void> {
    cart.changeQuality({ productId, quality })
    if (quality === 0) {
      cart.increaseDeletedItems()
    }
  }

  async addToCart(productId: Scalars['ID']): Promise<void> {
    cart.addItem({ productId, count: 1 })
  }

  created() {
    this.fetch()
    cart.resetDeletedItems()
  }

  async fetch() {
    const systemState = await terminalService.getSystemState()
    if (Array.isArray(systemState) && systemState.length > 0) {
      systemState.forEach((v) => {
        if (v !== null) this.systemState.push(v)
      })
    }
  }

  payBtnClickHandler(provider: Provider) {
    if (this.deletedItems) {
      this.lastPayProvider = provider
      this.$bvModal.show('purchase-cancel-alert-basket')
    } else {
      this.goToPayPage(provider)
    }
  }

  goToPayPage(provider: Provider | null) {
    if (provider !== null) {
      this.$router.push({
        name: RouteNames.payment,
        query: {
          provider
        }
      })
    }
  }

  get activePaymentProvider() {
    return terminalModule.getters.activePaymentProvider
  }

  get availableSberbankPayment() {
    return terminalModule.getters.sberbankAuthData !== null
  }

  get availableYandexPayment() {
    return terminalModule.getters.yandexGoAuthData !== null
  }

  get availableYandexBadgePayment() {
    return terminalModule.getters.yandexBagdeAuthData !== null
  }

  get availableFoodCard() {
    return terminalModule.getters.foodCardAuthData !== null
  }

  get availableNaLunchPayment() {
    return terminalModule.getters.nalunchAuthData !== null
  }

  get availableObedRuPayment() {
    return terminalModule.getters.obedRuAuthData !== null
  }

  get isShowAddToBasket() {
    return !terminalModule.getters.terminal?.isDemoMode
  }

  mounted():void {
    window.scrollTo({ top: 0 })
  }

  get cartLoading() {
    return !this.totalPrice
  }
}
